import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Catalogue from "../components/Catalogue"

type Props = PageProps & {
  data: {
    cataloguePage: SCM.CataloguePage
  }
}

const CatalogueTemplate: FunctionComponent<Props> = props => {
  const cataloguePage = props.data.cataloguePage

  return (
    <Layout>
      <SEO
        locale={cataloguePage.locale}
        uri={props.uri}
        breadcrumb={[
          {
            position: 1,
            name: "Catalogue",
            slug: "/catalogue",
          },
        ]}
        {...cataloguePage.seo}
      />
      <Catalogue {...cataloguePage} />
    </Layout>
  )
}

export default CatalogueTemplate

export const query = graphql`
  query CataloguePageQuery {
    cataloguePage {
      ...cataloguePageFragment
    }
  }
`
